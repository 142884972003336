/**
 * At least 8 characters
 * Require:
 *  At least 1 uppercase letter
 *  At least 1 lowercase letter
 *  At least 1 number
 */
export const validatePassword = (password) => {
  const LenRegex = /^.{8,}$/;
  const upperRegex = /[A-Z]/;
  const lowerRegex = /[a-z]/;
  const numberRegex = /[0-9]/;

  const minLenValidate = LenRegex.test(password);
  const upperValidate = upperRegex.test(password);
  const lowerValidate = lowerRegex.test(password);
  const numberValidate = numberRegex.test(password);

  const count =
    0 +
    (minLenValidate ? 0 : 1) +
    (upperValidate ? 0 : 1) +
    (lowerValidate ? 0 : 1) +
    (numberValidate ? 0 : 1);

  const validateObject = {
    rules: { minLenValidate, upperValidate, lowerValidate, numberValidate },
    verified: count === 0,
  };

  return validateObject;
};

export const PASSWORD_VALIDATION_MESSAGE = {
  minLenValidate: "Minimum 8 digits",
  upperValidate: "At least 1 upper case letters (A – Z)",
  lowerValidate: "At least 1 Lower case letters (a – z)",
  numberValidate: "At least 1 number (0 – 9)",
};
