import { Button, withStyles } from "@material-ui/core";
import React from "react";

const CustomButton = withStyles({
  root: {
    height: "100%",
    maxHeight: "100%",
    width: "100%",
    minWidth: 56,
    overflow: "hidden",
    color: "#fff",
    textTransform: "capitalize",
    "&.Mui-disabled": {
      border: "1px solid rgba(0, 0, 0, 0.26)",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:focus": {
      outline: "1px dotted",
      outlineColor: "transparent",
    },
  },
})(Button);

const CustomSignupButton = ({ onClick, children, isAPILoading }) => {
  return (
    <CustomButton
      disabled={isAPILoading}
      onClick={onClick}
      isLoading={isAPILoading}
    >
      {children}
    </CustomButton>
  );
};

export default CustomSignupButton;
