import {
  InputAdornment,
  Paper,
  TextField,
  withStyles,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import classNames from "classnames";
import React, { useEffect, useMemo, useRef } from "react";
import PasswordTooltip from "../../../components/password-tooltip";
import { borderColor, secondaryColor } from "../../../scss/colors.scss";
import { validatePassword } from "../../../utils/password-validate";
import CustomSignupButton from "./custom-signup-button";

const CssTextField = withStyles({
  root: {
    width: "100%",
    "& label.Mui-focused": {
      color: "#000",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: secondaryColor,
    },
    "& .MuiOutlinedInput-root": {
      alignItems: "stretch",
      "& fieldset": {
        top: 0,
        borderColor,
        "& legend": {
          display: "none",
        },
      },
      "&:hover fieldset": {
        borderColor: secondaryColor,
      },
      "&.Mui-focused fieldset": {
        borderColor: secondaryColor,
      },
      "& input": {
        cursor: "text",
        flex: "1 1",
      },
    },
  },
})(TextField);

const INPUT_HEIGHT = 56;

const CustomSignupInput = ({
  isDone,
  isActivated,
  question,
  activatedStep,
  placeholder,
  className = "",
  handleChange,
  handleBlur,
  fieldName,
  value,
  error = "",
  touched,
  handleClickButton,
  handleClickInput = () => {},
  fieldType = "text",
  customInputElement,
  autoFocus = true,
}) => {
  const inputRef = useRef();
  const checkValidatePassword = useMemo(() => {
    return validatePassword(value);
  }, [value]);
  const { verified } = checkValidatePassword;
  const handleOnKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleClickButton();
    }
  };

  useEffect(() => {
    if (isActivated && !!inputRef.current && autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus, isActivated]);

  const inputElement = customInputElement || (
    <CssTextField
      inputRef={inputRef}
      type={fieldType}
      disabled={isDone}
      name={fieldName}
      onChange={handleChange}
      onKeyDown={handleOnKeyDown}
      onClick={() => {
        handleClickInput();
        setTimeout(() => {
          inputRef.current.focus();
        }, 300);
      }}
      onBlur={handleBlur}
      value={value}
      variant="outlined"
      id="custom-css-outlined-input"
      autoComplete="new-password"
      helperText={error && touched && error}
      error={error && touched}
      placeholder={placeholder}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            className={classNames("custom-input-button-wrapper", {
              "d-none":
                value.length === 0 || (activatedStep === 3 && !verified),
            })}
          >
            <CustomSignupButton
              onClick={() => {
                return isDone ? () => {} : handleClickButton();
              }}
            >
              {isDone ? <CheckIcon /> : "Next"}
            </CustomSignupButton>
          </InputAdornment>
        ),
      }}
    />
  );

  return (
    <Paper
      style={{ top: isDone && activatedStep * INPUT_HEIGHT }}
      className={classNames(
        `custom-input-container sign-up-form-field ${className}`,
        {
          activated: isActivated,
          "field-done": isDone,
        }
      )}
    >
      <div
        className={classNames({
          "d-none": isDone,
        })}
      >
        {question}
      </div>
      <div className="w-100 text-left">
        {activatedStep === 3 && isActivated && (
          <PasswordTooltip open password={value} />
        )}
        {inputElement}
      </div>
    </Paper>
  );
};

CustomSignupInput.propTypes = {};

export default CustomSignupInput;
